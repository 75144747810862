.customModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 100;
  top: 0;
  left: 0;
  transition: 0.3s;
}

.customModalOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: black;
  opacity: 0.3;
  z-index: -1;
}

.modalContainer {
  background: #fff;
  padding: 140px 50px 100px 50px;
  border-radius: 40px;
  margin: 0 auto;
  max-width: 600px;
  position: relative;
}

.modalExit {
  width: 40px;
  height: 15px;
  float: right;
  background: none;
  position: absolute;
  padding: 0;
  border: none;
  outline: none !important;
  cursor: pointer;
  top: 80px;
  right: 40px;
}

.modalExit::before,
.modalExit::after {
  content: '';
  background-color: #191919;
  width: 100%;
  height: 2px;
  left: 0;
  position: absolute;
  transition: 0.1s ease-in-out;
}

.modalExit::before {
  top: 0;
}

.modalExit::after {
  bottom: 0;
}

.modalExit::before {
  transform: translateY(7px) rotate(45deg);
}

.modalExit::after {
  transform: translateY(-7px) rotate(-45deg);
}

@media only screen and (max-width: 1080px) {
  
}

@media only screen and (max-width: 760px) {
  .modalContainer {
    width: calc(100% - 40px);
    padding: 80px 20px;
  }

  .modalExit {
    top: 40px;
    right: 40px;
  }
}