.productSliderContainer {
  width: 100vw;
  height: 700px;
  display: flex;
  align-items: center;
  right: 0;
}

.productContentContainer {
  width: 60%;
  text-align: left;
  padding: 0 150px;
  box-sizing: border-box;
  position: relative;
}

.productContent {
  max-width: 400px;
  float: right;
  margin-right: 150px;
}

.productContent h2 {
  max-width: 200px;
  margin-bottom: 10px;
}

.productContent ul {
  padding: 0;
  list-style: none;
  margin: 0;
  margin-bottom: 60px;
}

.productContent ul li {
  padding-top: 20px;
  font-weight: 100;
  position: relative;
  font-family: 'Louis George Cafe', sans-serif;
  font-size: 18px;
  opacity: 0.6;
}

.productContent ul li::before {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  left: -20px;
  top: calc((100% + 20px) / 2);
}

.productContent a {
  font-family: 'Louis George Cafe', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #212529;
}

.productImageContainer {
  width: 40%;
  height: 100%;
}

.productImage {
  height: 100%;
  display: block;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.productSelection {
  position: absolute;
  right: 120px;
  bottom: -40px;
}

.productSelection ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.productSelection ul li {
  display: inline-block;
  margin: 0 6px;
}

.productSelection ul li button {
  cursor: pointer;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.1;
  transition: 0.3s;
  border: none;
  padding: 0;
  outline: none;
}

.productSelection ul li button:hover,
.productSelection ul li.selected button {
  opacity: 1;
}

@media only screen and (max-width: 1080px) {
  .productContentContainer {
    width: 50%;
    text-align: left;
    padding: 0 50px;
  }

  .productImageContainer {
    width: 50%;
    height: 100%;
  }

  .productContent {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 760px) {
  .productSliderContainer {
    width: calc(100vw - 16px);
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: unset;
    padding: 20px 20px;
  }

  .productContentContainer:last-of-type .productContent {
    margin-top: 40px;
  }

  .productContentContainer {
    width: 100%;
    padding: 0;
    display: inline-block;
    order: 2;
    padding: 0;
  }

  .productContent {
    float: none;
    margin-top: 30px;
  }

  .productImageContainer {
    width: 100%;
    height: 350px;
  }

  .productContent h2 {
    font-size: 30px;
    max-width: unset;
  }

  .productContent ul {
    margin-left: 22px;
  }

  .productSelection {
    display: none;
  }

  .productContent ul {
    margin-bottom: 30px;
  }
}
