body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

@font-face {
  font-family: 'made_canvasblack';
  src:
    url('../src/assets/fonts/made_canvas_black-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/made_canvas_black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'made_canvaslight';
  src:
    url('../src/assets/fonts/made_canvas_light-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/made_canvas_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Louis George Cafe';
  src: url('../src/assets/fonts/Louis\ George\ Cafe\ Light.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Louis George Cafe';
  src: url('../src/assets/fonts/Louis\ George\ Cafe.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Louis George Cafe';
  src: url('../src/assets/fonts/Louis\ George\ Cafe\ Bold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

p {
  font-weight: 100;
  font-family: 'Louis George Cafe', sans-serif;
  font-size: 18px;
}

h1, h2 {
  font-family: 'made_canvaslight', serif;
  font-size: 70px;
  font-weight: normal;
  line-height: 1;
}

body.menu-open {
  overflow-y: hidden;
  padding-right: 16px;
}

img {
  max-width: 100%;
}

button {
  background: none;
  border: none;
  padding: 0;
}

button.contentButton {
  display: block;
  font-family: 'Louis George Cafe', sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: black;
  margin-top: 40px;
  padding: 0px;
  position: relative;
}

button.contentButton::after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
}

button.contentButton:hover::after {
  opacity: 1;
}

button:focus {
  outline: none;
}

.section {
  padding: 150px 40px;
}

@media only screen and (max-width: 1080px) {
  h1, h2 {
    font-size: 50px;
  }

  .section {
    padding: 150px 20px;
  }
}

@media only screen and (min-width: 1080px) {
  
}

@media only screen and (max-width: 760px) {
  .section {
    padding: 70px 20px;
  }

  button.contentButton {
    margin-top: 20px;
  }
}
