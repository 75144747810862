.contactSuccessful {
  width: 100vw;
  height: 40px;
  background-color: #50624a;
  position: fixed;
  top: -40px;
  left: 0;
  color: #fff;
  display: flex;
  align-items: center;
  z-index: 50;
  transition: 0.3s;
}

:global(.contactSuccess) .contactSuccessful {
  top: 0;
}

.contactSuccessful .closeNotification {
  width: 15px;
  height: 15px;
  position: absolute;
  color: #fff;
  top: 12px;
  right: 40px;
}

.contactSuccessful .closeNotification::before, .contactSuccessful .closeNotification::after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 7.5px;
}

.contactSuccessful .closeNotification::before {
  transform: rotate(45deg);
}

.contactSuccessful .closeNotification::after {
  transform: rotate(-45deg);
}

.contactSuccessful p {
  margin: 0 auto;
  font-weight: 300;
}

header {
  margin: 40px;
  margin-top: 80px;
  margin-bottom: 150px;
  position: relative;
}

.headerContainer {
  padding: 350px 0 50px 200px;
  max-width: 90%;
  display: flex;
}

.headerCol {
  width: 50%;
  display: inline-block;
  position: relative;
  max-width: 500px;
}

.headerContainer h1 {
  line-height: 60px;
  margin-bottom: 20px;
}

.headerContainer .secondaryContent p {
  padding-left: 25%;
}

.headerContainer span.homeBlurb p {
  font-size: 30px;
  padding-left: 30px;
}

.topBar {
  z-index: 15;
  position: absolute;
  width: 100%;
  padding: 0px 30px;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 200px;
}

.menuButton {
  width: 40px;
  height: 15px;
  float: right;
  background: none;
  position: absolute;
  padding: 0;
  border: none;
  outline: none !important;
  cursor: pointer;
  right: 40px;
}

.menuButton::before,
.menuButton::after {
  content: '';
  background-color: #191919;
  width: 100%;
  height: 2px;
  left: 0;
  position: absolute;
  transition: 0.1s ease-in-out;
}

.menuButton::before {
  top: 0;
}

.menuButton::after {
  bottom: 0;
}

.menuButton.close::before {
  transform: translateY(7px) rotate(45deg);
}

.menuButton.close::after {
  transform: translateY(-7px) rotate(-45deg);
}

.growingBorder {
  content: '';
  border-bottom: 1px solid black;
  width: 100%;
  flex: 1;
  margin: 0 10px 7px 10px;
  opacity: 0.4;
}

.ctaLink {
  color: black;
  text-decoration: none;
  display: inline-block;
  font-family: 'Louis George Cafe', sans-serif;
  font-size: 14px;
}

.bannerImgContainer {
  position: relative;
  height: 800px;
  background-size: cover;
  background-position: bottom;
}

:global(.home-header) .bannerImgContainer, :global(.community-header) .bannerImgContainer{
  background-position: center;
}

.imgResponsive {
  width: 100%;
}

.captionLarge {
  font-family: 'made_canvaslight', serif;
  font-size: 7rem;
  line-height: 0.70;
  text-transform: uppercase;
  position: absolute;
  bottom: -40px;
  left: -30px;
  color: #796D77;
}

:global(.community-header) .captionLarge, :global(.home-header) .captionLarge {
  text-align: right;
  left: auto;
  right: -30px;
  color: #D7B29E;
}

 span {
  display: block;
}

.captionLarge span:nth-child(1) {
  margin-left: -5px;
}

.captionLarge span:nth-child(2) {
  margin-left: 70px;
}

.captionLarge span:nth-child(3) {
  margin-left: 30px;
}

@media only screen and (max-width: 1080px) {
  header {
    margin: 40px 20px;
  }

  .headerContainer {
    padding: 250px 0 50px 50px;
  }

  .captionLarge {
    font-size: 5rem;
    right: -18px;
  }

  :global(.community-header) .captionLarge, :global(.home-header) .captionLarge {
    right: -18px;
  }
}

@media only screen and (max-width: 760px) {
  .topBar {
    padding: 0;
    top: 30px;
  }

  .menuButton {
    right: 0;
  }

  .headerContainer {
    padding: 250px 0 50px 0px;
    max-width: unset;
    display: block;
  }

  .headerContainer h1 {
    line-height: 40px;
    margin-bottom: 20px;
    max-width: 400px;
  }

  .bannerImgContainer {
    height: 600px;
  }

  .captionLarge {
    font-size: 3.5rem;
    bottom: 20px;
  }

  .headerCol {
    width: 100%;
    display: block;
    position: relative;
    max-width: unset;
  }

  .headerContainer .secondaryContent p {
    padding-left: 0;
  }

  .contactSuccessful {
    position: static;
    margin-top: -75px;
    height: auto;
  }

  :global(.contactSuccess) .contactSuccessful {
    margin-top: 0;
  }

  .contactSuccessful p {
    margin-right: 50px;
    padding: 10px;
  }

  .contactSuccessful .closeNotification {
    width: 15px;
    height: 15px;
    position: absolute;
    color: #fff;
    top: 28px;
    right: 25px;
  }
  
}
