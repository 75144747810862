.productSlider {
  padding: 150px 0px;
  height: 700px;
  position: relative;
  box-sizing: content-box;
  display: flex;
  width: fit-content;
}

@media only screen and (max-width: 760px) {
  .productSlider {
    height: auto;
    display: block;
    padding: 80px 0px;
  }
}
