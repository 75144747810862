.overlayBlockContainer {
  padding-top: 200px;
  max-width: 1800px;
  position: relative;
  display: flex;
}

:global(.section).overlayBlock {
  position: relative;
  top: 80px;
  display: inline-block;
  width: 100%;
}

.overlayBlockContainer.alternate {
  width: 100%;
  float: right;
}

.overlayBlockImage {
  width: 100%;
  height: 0px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-size: cover;
  background-position: center;
}

.overlayBlockContentContainer {
  width: 100%;
}

.overlayBlockContent {
  float: right;
  background-color: #FFF;
  max-width: 600px;
  padding: 100px;
  padding-bottom: 70px;
  position: relative;
  top: 30px;
  min-height: 450px;
}

.alternate .overlayBlockContent {
  float: left;
}

.overlayBlockContent h2 {
  margin-bottom: 10px;
  margin-top: 0;
}

@media only screen and (max-width: 1080px) {
  .overlayBlockContent {
    max-width: 500px;
    padding: 50px;
    padding-bottom: 30px;
    min-height: 420px;
  }
}

@media only screen and (max-width: 760px) {
  .overlayBlockContainer {
    display: block;
    padding: 0px;
  }

  .overlayBlockContent {
    padding: 0px;
    min-height: none;
    position: static;
    float: none;
    min-height: unset;
    max-width: unset;
  }

  .overlayBlockContentContainer {
    display: inline-block;
  }

  .overlayBlockImage {
    height: 300px;
    position: static;
    margin-bottom: 50px;
  }
}
