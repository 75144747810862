.iconGridContainer {
  padding: 0px 20px;
  padding-bottom: 100px;
}

.iconGridContainer h2 {
  font-family: 'made_canvaslight', serif;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
}

.iconContainer {
  padding: 60px 60px 40px 60px;
  background: #cfd8cb;
  color: #50624a;
  margin: 0 auto;
  max-width: 1200px;
}

.iconBlock {
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Louis George Cafe', sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 20px;
  text-align: center;
}

.iconImage {
  width: auto;
  max-height: 70px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1080px) {
  .iconContainer {
    padding: 30px;
  }
}

@media only screen and (max-width: 760px) {
  .iconContainer {
    padding: 20px;
  }

  .iconImage {
    max-height: 55px;
  }
}
